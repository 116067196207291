import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import Container from "./Container";
import TitleComponent from "./TitleComponent";
import { StaticImage } from "gatsby-plugin-image";
import ModalComponent from "./modal";
import MasterPlan from "../images/master_plan.jpg";

const FloorPlanComponet = () => {
  const [State, setState] = useState(0);

  useEffect(() => {
    setState(window.innerWidth);
  }, [State]);

  return (
    <>
      <Container>
        <TitleComponent
          title="Site And Floor Plan"
          size="3vw"
          color="#7a8c57"
        />

        <div style={{ textAlign: "center" }}>
          <TitleComponent title="Master Plan" size="1.5vw" color="black" />
          <br/>
          <div css={two}>
            <div></div>
            <div className="OverlayContainer">
            {State > 600 ? (
                <StaticImage
                  src="../images/master_plan.jpg"
                  alt="Sumadhura folium"
                  placeholder="blurred"
                  layout="fullWidth"
                  height={400}
                />
              ) : (
                <img src={MasterPlan} alt="masterPlan" alt="master Plan" css={mobileOnly}/>
              )}
              <div className="overlay">
                <></>
              </div>
              <div className="overlay1">
                <ModalComponent title="Master Plan" class="overlayfinal" />
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <br />
        <br />
        <div>
          <TitleComponent title="Floor Plan" size="1.5vw" color="black" />
          <br />
          <div css={FloorCss}>
            <div className="OverlayContainer">
              {" "}
              {State > 600 ? (
                <StaticImage
                  src="../images/FloorPlan_1BHK.jpg"
                  alt="Sumadhura folium"
                  placeholder="blurred"
                  layout="constrained"
                  aspectRatio={3.5 / 3}
                />
              ) : (
                <StaticImage
                  src="../images/FloorPlan_1BHK.jpg"
                  alt="Sumadhura folium"
                  placeholder="blurred"
                  layout="constrained"
                  height={700}
                  width={1020}
                />
              )}
              <div className="overlay">
                <></>
              </div>
              <div className="overlay1">
                <ModalComponent title="Type 1BHK" class="overlayfinal" />
              </div>
              <div className="at-property-dis effetGradient">
                <h5>Type 1BHK</h5>
              </div>
            </div>
            <div className="OverlayContainer">
              {" "}
              {State > 600 ? (
                <StaticImage
                  src="../images/FloorPLan_2BHK.jpg"
                  alt="Sumadhura folium"
                  placeholder="blurred"
                  layout="constrained"
                  aspectRatio={3.5 / 3}
                />
              ) : (
                <StaticImage
                  src="../images/FloorPLan_2BHK.jpg"
                  alt="Sumadhura folium"
                  placeholder="blurred"
                  layout="constrained"
                  height={700}
                  width={1020}
                />
              )}
              <div className="overlay">
                <></>
              </div>
              <div className="overlay1">
                <ModalComponent title="Type 2BHK" class="overlayfinal" />
              </div>
              <div className="at-property-dis effetGradient">
                <h5>Type 2BHK</h5>
              </div>
            </div>
            <div className="OverlayContainer">
              {" "}
              {State > 600 ? (
                <StaticImage
                  src="../images/FloorPlan_2.5BHK.jpg"
                  alt="Sumadhura folium"
                  placeholder="blurred"
                  layout="constrained"
                  aspectRatio={3.5 / 3}
                />
              ) : (
                <StaticImage
                  src="../images/FloorPlan_2.5BHK.jpg"
                  alt="Sumadhura folium"
                  placeholder="blurred"
                  layout="constrained"
                  height={700}
                  width={1020}
                />
              )}
              <div className="overlay">
                <></>
              </div>
              <div className="overlay1">
                <ModalComponent title="Type 2.5 BHK" class="overlayfinal" />
              </div>
              <div className="at-property-dis effetGradient">
                <h5>Type 2.5 BHK</h5>
              </div>
            </div>
            <div className="OverlayContainer">
              {" "}
              {State > 600 ? (
                <StaticImage
                  src="../images/FloorPLan_3BHK.jpg"
                  alt="Sumadhura folium"
                  placeholder="blurred"
                  layout="constrained"
                  aspectRatio={3.5 / 3}
                />
              ) : (
                <StaticImage
                  src="../images/FloorPLan_3BHK.jpg"
                  alt="Sumadhura folium"
                  placeholder="blurred"
                  layout="constrained"
                  height={700}
                  width={1020}
                />
              )}
              <div className="overlay">
                <></>
              </div>
              <div className="overlay1">
                <ModalComponent title="Type 3BHK" class="overlayfinal" />
              </div>
              <div className="at-property-dis effetGradient">
                <h5>Type 3BHK</h5>
              </div>
            </div>
            <div className="OverlayContainer">
              {" "}
              {State > 600 ? (
                <StaticImage
                  src="../images/FloorPLan_3.5BHK.jpg"
                  alt="Sumadhura folium"
                  placeholder="blurred"
                  layout="constrained"
                  aspectRatio={3.5 / 3}
                />
              ) : (
                <StaticImage
                  src="../images/FloorPLan_3.5BHK.jpg"
                  alt="Sumadhura folium"
                  placeholder="blurred"
                  layout="constrained"
                  height={700}
                  width={1020}
                />
              )}
              <div className="overlay">
                <></>
              </div>
              <div className="overlay1">
                <ModalComponent title="Type 3.5 BHK" class="overlayfinal" />
              </div>
              <div className="at-property-dis effetGradient">
                <h5>Type 3.5 BHK</h5>
              </div>
            </div>
          </div>
        </div>
        <br />
      </Container>
    </>
  );
};

export default FloorPlanComponet;

const FloorCss = css`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
  justify-content: space-between;
  grid-gap: 10px 12px;
  padding: 0px 30px;
  @media (max-width: 600px) {
    display: block;
    padding: 0px;
  }
  div {
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  }
`;

const two = css`
  display: grid;
  grid-template-columns: 4fr 4fr 4fr;
  @media(max-width:600px){
    margin-bottom: -6vh;
  }
`;

const mobileOnly = css`
  height:25vh;
`